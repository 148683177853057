import { Injectable } from '@angular/core';
import { filter_page, result_page, page, result_modified } from './interfaces.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor( 
    private http: HttpClient
  ) { }

  //Load all page with proper filter and cms_type 
  Load( _cms_type: string, _filters: filter_page  ): Observable<result_page>{
    let params = new HttpParams();
    for (var key in _filters) {
      params = params.set( key, _filters[key] );
    }
    return this.http.get<result_page>( environment.api_url + "/" + _cms_type + "", 
      { params: params } 
    );
  }

  //The same with Load Above, but we will do download instead
  Download( _cms_type: string, _filters: filter_page  ){
    let params = new HttpParams();
    for (var key in _filters) {
      params = params.set( key, _filters[key] );
    }
    this.http.get( environment.api_url + "/" + _cms_type + "/page/csv",
      { params: params, responseType: 'text' } 
    ).subscribe( _data => {
      let filename = "data.csv";
      var blob = new Blob([_data], { type: 'text/csv;charset=utf-8;' });
      if ( /* navigator.msSaveBlob */ false ) { // IE 10+
          //navigator.msSaveBlob(blob, filename);
      } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
      }
    });
    //window.open( environment.api_url + "/" + _cms_type + "/page/csv?" + params.toString(),  "_self", "location=yes");
  }

  Add( _cms_type: string, _page_data: page[] ):Observable<result_modified>{
    let target_data;
    if( _page_data.length > 1 ){
      //Multiple
      target_data = { data: _page_data };
    }else{
      target_data = { data: _page_data };
    }
    return this.http.post<result_modified>( environment.api_url + "/" + _cms_type + "/page", target_data );
  }

  Edit( _cms_type: string, _page_data: page ):Observable<result_modified>{
    return this.http.put<result_modified>( environment.api_url + "/" + _cms_type + "/page", _page_data );
  }
  Delete( _cms_type: string, _page_data: page ):Observable<result_modified>{
    let params = new HttpParams();
    for (var key in _page_data) {
      params = params.set( key, _page_data[key] );
    }
    return this.http.delete<result_modified>( environment.api_url + "/" + _cms_type + "/page", { params: params } );
  }

  /**
   * JOB Related Newly Added Module
   */

  //20220311 :: For Show Page Detail Purposes
  public selected_page: page = null;
  JobEdit( _page_data: page ):Observable<result_modified>{
    return this.http.put<result_modified>( environment.api_url + "/external/job/page", _page_data );
  }

}
