import { Component, OnInit } from '@angular/core';
import { LoadingController, AlertController, ModalController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { StaffService } from 'src/app/services/staff.service';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss'],
})
export class EditPasswordComponent implements OnInit {

  form_data: any = {
    old_password: "",
    new_password: "",
    new_password2: ""
  };

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private auth_service: AuthenticationService
  ) { }

  ngOnInit() {}


  on_submit(){
    console.log( this.form_data );

    this.loadingCtrl
    .create({ keyboardClose: true, message: 'Reset Password, please wait...' })
    .then(loadingEl => {
      loadingEl.present();
      this.auth_service.on_edit_password( this.form_data ).subscribe( resData => {
        console.log( "Reset Successful", resData );
        if( resData.status == "ok" ){
            this.alertCtrl.create({
              header: "Password Changed",
              message: "Please login using new password",
              buttons: [
                {
                  text: "Login Ulang",
                  handler: _ => {
                    this.modalCtrl.dismiss( null, "cancel", "EditPassword" );
                    this.auth_service.logout();
                  }
                }
              ]
            }).then( alertElSuccess => {
              alertElSuccess.present();
            }).then( alertElSuccess => {
              return true;
            });
            return true;
        }else{
          this.alertCtrl.create({
            header: "Error",
            message: resData.status_message,
            buttons: ['Dismiss']
          }).then( alertEl => {
            alertEl.present();
          });
        }
      }).add( _ => {
        loadingEl.dismiss();
      });
    });
  }
  presentAlert( header, message ) {
    let alert = this.alertCtrl.create({
      header: header,
      message: message,
      buttons: ['Dismiss']
    }).then( alertEl => {
      alertEl.present();
    }); 
  }
  on_close(){
    this.modalCtrl.dismiss( null, "cancel", "ResetModal" );
  }

}
