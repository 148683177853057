import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastController, AlertController } from '@ionic/angular';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  
  constructor(
    private auth_service: AuthenticationService,
    public toastController: ToastController,
    private alertCtrl: AlertController) {
    }
 
  // Intercepts all HTTP requests!
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ( this.auth_service.account_token != "" && this.auth_service.staff_id != "" ) {
      request = request.clone({
        setHeaders: {
          'Authorization': "staff|" + this.auth_service.staff_id+ "|" + this.auth_service.account_token
        }
      });
    }else if ( this.auth_service.account_token != "" && this.auth_service.member_id != "" ) {
      request = request.clone({
        setHeaders: {
          'Authorization': "member|" + this.auth_service.member_id+ "|" + this.auth_service.account_token
        }
      });
    }else{
      request = request.clone({
        setHeaders: {
          'Authorization': "ikt.co.id|" + environment.token_key
        }
      });
    }

    if( request.url.indexOf("csv") > 0 ){
      if (!request.headers.has('Content-Type') && !request.headers.has('enctype')) {
        request = request.clone({
          setHeaders: {
            'content-type': 'text/csv'
          }
        });
      }
      request = request.clone({
        headers: request.headers.set('Accept', 'text/csv')
      });
    }else{
      if (!request.headers.has('Content-Type') && !request.headers.has('enctype')) {
        request = request.clone({
          setHeaders: {
            'content-type': 'application/json'
          }
        });
      }
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json')
      });
    }

    
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //console.log('event--->>>', event);
          if( event.body == null ){
            console.log('Null Body event--->>>', event);
          }else if( event.body.status == "error" && ( event.body.status_message.indexOf("Login First") >= 0 ||
              event.body.status_message.indexOf("Token Expired") >= 0 ) ){
            
            //Never show alert for Logout Request
            if( request.url.indexOf("signout") > 0 ){

            }else{
              //Show Alert
              if( this.has_alert == false ){
                this.has_alert = true;
                this.alertCtrl.create( { 
                  header: "Login Failure",
                  message: "Access Denied",
                  buttons: ['Close', {
                    text:'Hubungi Kami ( WA )', handler: () =>{
                      this.OpenWhatsapp();
                    }
                  }]
                }).then( alertEl =>{
                  alertEl.present();
                  alertEl.onDidDismiss().then( _ => {
                    this.has_alert = false;
                  });
                });
              }
              
            } 
            //Redirect to AUTH again ( FORCED LOGOUT )
            this.auth_service.logout( true );
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log( "Interceptor Error", error );
        if ( error.status === 401 || error.status >= 500 ) {
          this.presentToast( error.error );
          this.auth_service.logout( true );
        }
        return throwError(error);
      }));
  }
  has_alert: boolean = false;

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  OpenWhatsapp(){
    window.open( environment.whatsapp, "_system", "location=yes");
  }
}
