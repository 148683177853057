import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { popover_button } from '../components/community-post-popover/community-post-popover.component';
import { badge, page, result_general, result_member, result_modified, result_page } from './interfaces.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Community CRUD
   */
   Load( _filters: filter_community  ): Observable<result_community>{
    let params = new HttpParams();
    for (var key in _filters) {
      params = params.set( key, _filters[key] );
    }
    return this.http.get<result_community>( environment.api_url + "/community/page", 
      { params: params } 
    ).pipe( tap( _data => {
      this.setup_info[ _filters["community_name_id"] ] = _data.meta_data.setup_info;
    }));
  }
  public setup_info: object = new Object();


  Add( _community_data: community ):Observable<result_modified>{
    return this.http.post<result_modified>( environment.api_url + "/community/page", _community_data );
  }
  Edit( _community_data: community ):Observable<result_modified>{
    return this.http.put<result_modified>( environment.api_url + "/community/page/" + this.selected_community_id, _community_data );
  }
  Delete( _cms_type: string, _community_data: community ):Observable<result_modified>{
    let params = new HttpParams();
    for (var key in _community_data) {
      params = params.set( key, _community_data[key] );
    }
    if( _cms_type == "master" ){
      _cms_type = "item";
    }
    return this.http.delete<result_modified>( environment.api_url + "/" + _cms_type + "/community", { params: params } );
  }


  /**
   * Community ionic-selectable
   */
   public communities_filtered: community[] = [];
   public communities: community[] = [];
   community_subscription: Subscription;
   on_community_search(
     event: {
       component: IonicSelectableComponent,
       text: string
     }
   ){
     let text = event.text.trim().toLowerCase();
     event.component.startSearch();
 
     // Close any running subscription.
     if (this.community_subscription) {
       this.community_subscription.unsubscribe();
     }
 
     if (!text && false) {
       // Close any running subscription.
       if (this.community_subscription) {
         this.community_subscription.unsubscribe();
       }
 
       event.component.items = [];
       event.component.endSearch();
       return;
     }
 
     this.community_subscription = this.Load( { keyword: text } ).subscribe( communitys => {
       // Subscription will be closed when unsubscribed manually.
       if (this.community_subscription.closed) {
         return;
       }
       /*
       event.component.items = [];
       for( let i = 0 ; i < 20 ; i++ ){
        for( let single_data of communitys.data ){
          event.component.items.push( single_data );
         }
       }
       /* */
       
       event.component.items = communitys.data;
       event.component.endSearch();
     });
   }


  /**
   * Community Membership CRUD
   * Difference between this vs Community Member, is that this handles SELF MEMBERSHIP of certain community
   * That is there is CRUD there :)
   */
  public memberships: membership[];
  membership_load( _filters = [] ){
    let params = new HttpParams();
    for (var key in _filters) {
      params = params.set( key, _filters[key] );
    }
    let url: string = environment.api_url + "/community/membership";
    return this.http.get<result_membership>( url, { params: params } );
  }

  membership_add( _target_community: community ){
    let url: string = environment.api_url + "/community/membership/" + _target_community.community_id;
    return this.http.post<result_modified>( url, _target_community);
  }

  membership_delete( _target_membership: membership ){
    let url: string = environment.api_url + "/community/membership/" + _target_membership.community_member_id;
    return this.http.delete<result_modified>( url);
  }

  //20211026 :: This can only be performed by our PENGURUS / STAFF, ONLY the status and privileges can be changed
  membership_edit( _target_membership: membership ){
    let url: string = environment.api_url + "/community/member/" + _target_membership.community_id;
    return this.http.put<result_modified>( url, _target_membership);
  }



  //This is settable , which will govern all of the Community Post / Community Member
  public selected_community_id: number = 0;
  public selected_community_name: string = "";
  public selected_community_location: string = "";
  public selected_membership_type: string = "";
  public selected_membership_privileges: string = "";
  public selected_community_kecamatan: string = "Kelapa gading";
  public selected_community_kabupaten: string = "Jakarta Utara";

  /**
   * Community Post CRUD
   */
  public selected_post: page = null;
  community_post_load( _filters ){
    let params = new HttpParams();
    for (var key in _filters) {
      params = params.set( key, _filters[key] );
    }
    let url: string = environment.api_url + "/community/post/" + this.selected_community_id;
    return this.http.get<result_page>( url, { params: params } );
  }
  community_post_add( _post: page ){
    let url: string = environment.api_url + "/community/post/" + this.selected_community_id;

    //20220102 :: Add Notification Stuffs
    _post.send_notification = true;
    _post.notify_member_id = "";
    _post.notify_member_type = "";
    _post.notify_member_privilege = "";
    _post.notify_member_status = "accepted";
    _post.notify_label_title = "member_name";
    _post.notify_label_message = "info_1";
    if( _post.page_category_id == "3613_38" ){
      _post.notify_label_message = "category";
      _post.notify_member_type = "pengurus";
      _post.notify_member_privilege = "emergency_handler";
    }
    
    return this.http.post<result_modified>( url, _post );
  }
  community_post_edit( _post: page ){
    let url: string = environment.api_url + "/community/post/" + this.selected_community_id;
    return this.http.put<result_modified>( url, _post );
  }
  community_post_delete( _post: page ){
    let params = new HttpParams();
    params = params.set( "page_id", _post.page_id );
    let url: string = environment.api_url + "/community/post/" + this.selected_community_id ;
    return this.http.delete<result_modified>( url, { params: params } );
  }

  /**
   * Community Post Comment CRUD
   */
  community_post_comment_add( _post: page ){
    let url: string = environment.api_url + "/community/post/" + this.selected_community_id + "/comment";
    return this.http.post<result_modified>( url, _post );
  }


  /**
   * Community Member Load Only ( duh, we can never ever )
   */
  community_member_load( _filters:filter_membership = {} ){
    let params = new HttpParams();
    for (var key in _filters) {
      params = params.set( key, _filters[key] );
    }
    let url: string = environment.api_url + "/community/member/" + this.selected_community_id;
    return this.http.get<result_membership>( url, { params: params } );
  }


  check_community_id( _community_id ){

  }
}


export interface community{
  community_id?: number;
  name?: string;

  address?: string;
  provinsi?: string;
  kabupaten?: string;
  kecamatan?: string,
  kelurahan?: string;
  kodepos?: string;
  destination_code?: string;

  info_1?: string;
  info_2?: string;
  info_3?: string;

  total_member?: number;

  profile_picture?: string;
  banner_picture?: string;
}
export interface result_community extends result_general{
  data?: community[];
}
export interface filter_community{

}
export interface membership{
  added_date?: number;
  approved_by_member_id?: string;
  approved_by_member_type?: string;
  approved_date?: string;
  community_id?: any;
  community_member_id?: number;
  info_1?: string;
  info_2?: string;
  info_3?: string;
  member_info_1?: string;
  member_id?: string;
  membership_type?: string;
  name?: string;
  privileges?: string;
  profile_picture?: string;
  search_fast?: string;
  status?: string;

  kelurahan?: string;
  kecamatan?: string;
  kabupaten?: string;
  provinsi?: string;

  //Generated Badges
  badges?: badge[];
  formatted_added_date?: string;

  //We will allow popover actions
  popover_arr?: popover_button[];
  has_popover?: boolean;

  image_url?: string[];
}

export interface result_membership extends result_general{
  data?: membership[];
}

export interface filter_membership{
  member_id?: string;
}
