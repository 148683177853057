import { ElementRef, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { file_image, result_file_image_get, result_modified } from './interfaces.service';
import { Observable } from 'rxjs';
import { ActionSheetController, LoadingController } from '@ionic/angular';

/* Camera Upload 
npm install @ionic/pwa-elements
npm install @capacitor/camera
*/
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { CommunityService } from './community.service';

export interface file_parent {
  cms_type: string;
  edit_type: string;
  primary_id: string;
  file_data_id?: string;
  upload_type?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor( private community: CommunityService, private http: HttpClient, private actionCtrl: ActionSheetController, private loadingCtrl: LoadingController ) { }

  Load( file_data: file_image ) {
    //return this.http.get<file_image[]>( this.get_api_url( file_data ) );
  }
 
  uploadImage( _target_parent:file_parent, target_file: file_image ):Observable<result_file_image_get> {
    const httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data;'
      })
    };
    const formData = new FormData();
    target_file.uploading = true;
    formData.append("Filedata", target_file.file_blob, target_file.file_name );
    formData.append("force_jpg", target_file.force_jpg );
    if( typeof _target_parent["upload_type"] != "undefined"){
      formData.append("upload_type", _target_parent["upload_type"] );
    }
    if( typeof _target_parent["primary_id"] ){
      formData.append("primary_id", _target_parent["primary_id"] );
    }
    console.log( _target_parent );
    return this.http.post<result_file_image_get>(  this.get_api_url( _target_parent ), formData, httpOptions);
  }
 
  deleteImage( _target_parent:file_parent, _file_data_id: string ):Observable<result_modified> {
    _target_parent.file_data_id = _file_data_id;
    return this.http.delete<result_modified>( this.get_api_url( _target_parent ) );
  }
  Edit( _target_parent: file_parent, _file_data_id: string, _data ):Observable<result_modified>{
    _target_parent.file_data_id = _file_data_id;
    return this.http.put<result_modified>( this.get_api_url( _target_parent ), _data );
  }

  get_api_url( file_data: file_parent ): string{
    let api_url = environment.api_url;
    if( file_data.cms_type == "community" ){
      api_url += "/" + file_data.cms_type;
      api_url += "/page";
      api_url += "/" + this.community.selected_community_id;
      api_url += "/image" ;
    }else if( file_data.cms_type == "community_post" ){
      api_url += "/community";
      api_url += "/post";
      api_url += "/" + this.community.selected_community_id;
      api_url += "/image" ;
    }else{
      api_url += "/" + file_data.cms_type;
      api_url += "/" + file_data.edit_type;
      api_url += "/" + file_data.primary_id;
      api_url += "/image" ;
    }
    
    if( typeof file_data.file_data_id !== "undefined" && file_data.file_data_id != null ){
      api_url += "/" + file_data.file_data_id;
    }
    console.log( api_url );
    return api_url;
  }



  /* File Select & File Upload */
  images: file_image[] = [];
  target_data: any = {};
  target_data_refresh: Function = null;
  public async on_file_select( _target_data: any, fileInput: ElementRef = null, _target_data_refresh: Function = null ) {
    this.target_data = _target_data;
    this.target_data_refresh = _target_data_refresh;
    console.log( _target_data );
    if( fileInput == null ){
      console.log( "Camera Upload" );
      this.on_camera_upload();
      return true;
    }
    
    const buttons = [
      {
        text: 'Take Photo',
        icon: 'camera',
        handler: () => {
          this.on_camera_upload();
        }
      }
    ];

    // Only allow file selection inside a browser
    if ( /* !this.platform.is('hybrid') || */ 1 == 1) {
      buttons.push({
        text: 'Choose a File',
        icon: 'attach',
        handler: () => {
          fileInput.nativeElement.click();
          console.log( "Test", fileInput );
        }
      });
    }

    const actionSheet = await this.actionCtrl.create({
      header: 'Select Image Source',
      buttons
    });
    await actionSheet.present();
  }

  public total_file_upload: number = 0;
  public total_file_uploaded: number = 0;
  public file_upload_progress: number = 0;
  public file_upload_buffer: number = 0;
  public async on_camera_upload() {
    try{
      console.log( "Camera Upload Inside" );
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Base64,
        source: CameraSource.Prompt
      });
      console.log( image );
      const blobData = this.b64toBlob(image.base64String, `image/${image.format}`);
      const imageName = "photo " + this.target_data.name + "." + image.format;
      this.create_not_yet_uploaded( blobData, imageName, "1" );
      this.on_file_upload_execute();
    }catch( err ){
      console.log( err );
    }
    
  }
 
  // Used for browser direct file upload
  on_file_upload(event: any) {
    /*
    //CHECK BACK AGAIN LATER
    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    let file_count: number = target.files.length;
    if( file_count > 0 ){
      for( let i = 0 ; i < file_count ; i++ ){
        this.create_not_yet_uploaded( target.files[i], target.files[i].name, "0" );
      }
      //Execute Real Upload
      this.on_file_upload_execute();
    }
    //Reset, so that we can upload the same file again and again
    target.value = '';
    */
  }

  public file_not_yet_uploaded: file_image[] = [];
  create_not_yet_uploaded( _file: Blob, _file_name: string, _force_jpg: string ){
    let new_file: file_image = {
      file_data_id: Math.random().toString(),
      file_name: _file_name,
      image_url: "",
      file_blob: _file, uploading: false, force_jpg: _force_jpg
    };
    let reader = new FileReader();
    reader.onload = (event:any) => {
      new_file.file_blob_url = event.target.result;
    }
    reader.readAsDataURL( new_file.file_blob );
    this.file_not_yet_uploaded.push( new_file );
  }

  //Execute Upload One By One
  //Check the items
  target_parent_id: string[] = [];
  file_uploaded: Function = function( _result: string ): void{ 
    console.log( "Empty File Uploaded Callback", this.target_data_refresh ); 
    if( this.target_data_refresh != null ){
      this.target_data_refresh();
    }
  };
  /*
  public file_upload_execute( _success: modified[], _function: Function ){
    if( _success.length > 0 ){
      //For each success upload 1 file, the last one will receive all file upload
      this.target_parent_id = [];
      this.file_uploaded = _function;
      for( let single_success of _success ){
        this.target_parent_id.push( single_success.id );
      }
      this.on_file_upload_execute();
    }
  }
  */
  on_file_upload_execute(){
    if( this.file_not_yet_uploaded.length > 0 ){
      let target_file = this.file_not_yet_uploaded[0];
      this.loadingCtrl.create({ message: "Uploading..." }).then(loadingEl => {
        loadingEl.present();
        
        this.uploadImage( this.target_data, target_file ).subscribe(( result : result_file_image_get ) => {
          console.log( result );
          if( result.data.success.length > 0 && result.data.uploaded.length > 0 ){
            if( typeof this.target_data.file != "undefined" ){
              this.target_data.file.push(result.data.uploaded[0]);
            }
          }
          this.file_not_yet_uploaded.shift();
          if( this.file_not_yet_uploaded.length > 0 ){
            this.on_file_upload_execute();
          }else{
            this.file_uploaded();
          }
        }).add( ()=> {
          loadingEl.dismiss();
        });
      });
      
    }
  }
 
  // Helper function
  // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
 
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
 
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
 
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
 
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}