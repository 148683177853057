/**
 * The most important service
 * This service will only Load SETUP JSON, and parsed them, the whole application will behave according to the SETUP
 * We might load all of necessary LAYOUT / DATA / DESIGN , parsed into Ionic Friendly format
 * 
 * Color & Font also setted here
 * 
 * @author Taufik Chowi
 * @version 1.0
 * @since 2020-04-19
 */
 import { HttpClient } from '@angular/common/http';
 import { Injectable } from '@angular/core';
 import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
 import { single } from 'rxjs/operators';
 import { environment } from 'src/environments/environment';
 import { filter_page } from './interfaces.service';
 import { PageService } from './page.service';
 import { App } from '@capacitor/app';
 import { Capacitor } from '@capacitor/core';
 import { SplashScreen } from '@capacitor/splash-screen';
 
 
 @Injectable({
   providedIn: 'root'
 })
 export class SetupService {
 
   constructor(
     private http: HttpClient, private router: Router, private page_service: PageService, private alertCtrl: AlertController
   ) { 
     
   }
 
  //Load the setup from https://api.ikt.co.id/ID/setup
  //for now we will load from our assets ( perhaps we should always have the JSON as default )
  platform: string = "web";
  async Load(){
    //Load the SETUP Here
    SplashScreen.show({ autoHide: false });
     let promise = new Promise((resolve, reject) => {
       this.http.get<result_setup_data>( environment.api_url + "/global", {} 
       /*
       this.http.get<setup>( 'assets/environment.json', 
       { }
       */ 
       ).subscribe( async setup_data => {
        await SplashScreen.hide();
          this.data_global = setup_data.data.global;
          this.data_setup = setup_data.data.setup;
          for( let single_data of this.data_global ){
            if( single_data.var_type == "[JSON] App Setup" ){
              //console.log( environment.api_url , single_data );
              this.loaded_setup = JSON.parse( single_data.data );
            }
          }
         //this.loaded_setup = setup;
         this.global_info = this.loaded_setup.global_info;
         environment.whatsapp = this.loaded_setup["data"]["whatsapp"];
          
          this.platform = Capacitor.getPlatform();
          let versionNumber = this.loaded_setup.version;
          if( this.platform != "web" ){
            const app_info = await App.getInfo();
            versionNumber = app_info.version;
          }else{
            versionNumber = "1.0";
          }
          let clientVersion = versionNumber.split(".");
          if( clientVersion.length == 2 ){
            clientVersion.push( "0" );
          }
          let serverVersion = this.loaded_setup.version.split(".");
          console.log( serverVersion, clientVersion, versionNumber, this.platform );
          this.InitSetup().then( _result => {
            let default_resolve: Function = () => {
              resolve( "done" );
            }
            if( this.loaded_setup.enabled == false ){
              //Simply show the message, that we are under maintenance
              this.present_alert( this.loaded_setup.maintenanceMsg, default_resolve);
             }else{
               if( serverVersion[0] > clientVersion[0] || serverVersion[1] > clientVersion[1] ){
                 this.present_alert( this.loaded_setup.majorMsg, default_resolve );
               }else if( serverVersion[2] > clientVersion[2] ){
                this.present_alert( this.loaded_setup.minorMsg, default_resolve );
               }else{
                default_resolve();
               }
              
             }
          });
         
         
       });
     });
 
     let result = await promise;
     console.log("HAHAHHAHA");
     
     return result;
   }

  private present_alert( _alert: update_msg, _continue: Function ){
    let buttons = [];
    let button_arr = _alert.btn.split(",");
    for( let button of button_arr ){
      if( button.length > 0 ){
        let handler: Function = () => { return false; };
        if( button == "close" ){
          handler = () => {
            this.alertCtrl.dismiss();
            _continue();
            return true;
          };
        }else if( button == "download" ){
          handler = () => {
            this.go_to_appstore();
            return false;
          };
        }
        buttons.push({
          text: button,
          handler: handler
        });
      }
    }
    this.alertCtrl.create({
      header: _alert.title,
      message: _alert.msg,
      buttons: buttons,
      backdropDismiss: false
    }).then( alertEl => {
      alertEl.present();
    });
  }
  go_to_appstore(){
    if( this.platform == "android" ){
      window.open( this.loaded_setup.playstore, "_system", "location=yes");
    }else{
      window.open( this.loaded_setup.appstore, "_system", "location=yes");
    }
    
  }
 
   private global_color_properties: string[] = [
     "background-color", "background-color-rgb", "text-color", "text-color-rgb",
     "step-50", 
     "step-100", "step-150",
     "step-200", "step-250",
     "step-300", "step-350",
     "step-400", "step-450",
     "step-500", "step-550",
     "step-600", "step-650",
     "step-700", "step-750",
     "step-800", "step-850",
     "step-900", "step-950",
     "border-color", "item-background", "toolbar-background", "toolbar-color", "tab-bar-background"
   ];
 
   private color_properties: string[] = [ "rgb", "contrast", "contrast-rgb", "shade", "tint" ];
   async InitSetup(){
     //Set the Global Theme 
     let target_design: design = this.loaded_setup.global;
     if( target_design != null ){
       this._header_color = target_design.header_color;
       this._footer_color = target_design.footer_color;
       this._menu_color = target_design.menu_color;
       this._popup_header_color = target_design.popup_header_color;
       this._price_color = target_design.price_color;
       this._button1_color = target_design.button1_color;
       this._button2_color = target_design.button2_color;
       this._button3_color = target_design.button3_color;
       this._title1_color = target_design.title1_color;
       this._title2_color = target_design.title2_color;
       this._title3_color = target_design.title3_color;
       this.filters = target_design.filters;
 
       if( typeof target_design.theme != "undefined" ){
         //Set Global Color Theme
         let target_color: string = "--ion-";
         for( let single_color_name of this.global_color_properties ){
           if( typeof target_design.theme[ single_color_name ] != "undefined" ){
             document.documentElement.style.setProperty( target_color + single_color_name, target_design.theme[ single_color_name ] );
           }
         }
 
         //Set Global Font Theme
         for( let single_font of target_design.theme.fonts ){
           document.documentElement.style.setProperty( "--ikt-font-" + single_font.name + "-font-family", single_font['font-family'] );
           document.documentElement.style.setProperty( "--ikt-font-" + single_font.name + "-font-size", single_font['font-size'] );
           document.documentElement.style.setProperty( "--ikt-font-" + single_font.name + "-font-weight", single_font['font-weight'] );
           document.documentElement.style.setProperty( "--ikt-font-" + single_font.name + "-letter-spacing", single_font['letter-spacing'] );
           document.documentElement.style.setProperty( "--ikt-font-" + single_font.name + "-text-align", single_font['text-align'] );
           document.documentElement.style.setProperty( "--ikt-font-" + single_font.name + "-display", single_font['display'] );
           document.documentElement.style.setProperty( "--ikt-font-" + single_font.name + "-margin", single_font['margin'] );
           document.documentElement.style.setProperty( "--ikt-font-" + single_font.name + "-padding", single_font['padding'] );
         }
 
         //Set Named Color Theme
         for( let single_color of target_design.theme.colors ){
           target_color  = "--ion-color-";
           target_color += single_color.name;
           document.documentElement.style.setProperty( target_color, single_color.base );
           target_color += "-";
           for( let single_color_name of this.color_properties ){
             if( typeof single_color[ single_color_name ] != "undefined" ){
               document.documentElement.style.setProperty( target_color  + single_color_name, single_color[ single_color_name ] );
             }
           }
         }
       }
     }
 
     //Set the Logo Variables for our app
     if( typeof this.loaded_setup.logo != "undefined" && this.loaded_setup.logo != null ){
       this.logo = this.loaded_setup.logo;
     }
     
 
     //Set the Special Design for each pages
     this.designs = {};
     for( let single_design of this.loaded_setup.design ){
       this.designs[ single_design.type ] = single_design;
     }
 
     //Load all thumbnails
     this.thumbnail_styles = {};
     for( let single_thumbnail of this.loaded_setup.thumbnail_style_id ){
       this.thumbnail_styles[ single_thumbnail.name ] = single_thumbnail;
     }
 
     //Load the Page for our logo_page_id 
     let promise = new Promise((resolve, reject) => {
       let filters: filter_page = { page_id: this.logo.page_id, thumbnail_style_id: this.thumbnails( "logo" ).style_id };
       this.page_service.Load( "blog", filters ).subscribe( _result => {
         for( let single_page of _result.data ){
           if( single_page.page_id == this.logo.page_id ){
             this.logo.url = single_page["image_url_" + filters.thumbnail_style_id ];
             this.logo.url_original = single_page["image_url"];
             if( this.logo.url_original && this.logo.url_original.length > 1 && false ){
              this.logo_img = this.logo.url_original[1];
             }else if( this.logo.url_original && this.logo.url_original.length > 0 ){
              this.logo_img = this.logo.url_original[0] 
             }
             
           }
         }
         resolve( "done" );
       });
     });
 
     let result = await promise;
     return result;
   }
 
   
 
   /**
    * The 11 Most Used Variables by all parts of our app
    * The setter is used to set the DEFAULT Value
    * The getter is used to retrieve either the DEFAULT Value or if the override is setted, then retrieve the overriden
    */
 
   public _popup_header_color: string = "secondary";
   public get popup_header_color(): string{
     return this.GetDesignColor( "popup_header_color", this._popup_header_color );
   }
 
   public _header_color: string = "light";
   public get header_color(): string{
     return this.GetDesignColor( "header_color", this._header_color );
   }
 
   public _footer_color: string = "dark";
   public get footer_color(): string{
     return this.GetDesignColor( "footer_color", this._footer_color );
   }
 
   public _menu_color: string = "secondary";
   public get menu_color(): string{
     return this.GetDesignColor( "menu_color", this._menu_color );
   }
 
   public _price_color: string = "primary";
   public get price_color(): string{
     return this.GetDesignColor( "price_color", this._price_color );
   }
 
   public _button1_color: string = "primary";
   public get button1_color(): string{
     return this.GetDesignColor( "button1_color", this._button1_color );
   }
 
   public _button2_color: string = "secondary";
   public get button2_color(): string{
     return this.GetDesignColor( "button2_color", this._button2_color );
   }
 
   public _button3_color: string = "tertiary";
   public get button3_color(): string{
     return this.GetDesignColor( "button3_color", this._button3_color );
   }
 
   public _title1_color: string = "primary";
   public get title1_color(): string{
     return this.GetDesignColor( "title1_color", this._title1_color );
   }
 
   public _title2_color: string = "secondary";
   public get title2_color(): string{
     return this.GetDesignColor( "title2_color", this._title2_color );
   }
 
   public _title3_color: string = "tertiary";
   public get title3_color(): string{
     return this.GetDesignColor( "title3_color", this._title3_color );
   }

   public _background_color: string = "background";
   public get background_color(): string{
     return this.GetDesignColor( "background_color", this._title3_color );
   }

 
   public designs: any;
   public GetDesignColor( _type: string, _default: string ){
     let url: string = this.router.url;
     if( this.designs && typeof this.designs[ url ] != "undefined" && typeof this.designs[ url ][ _type ] != "undefined" ){
       return this.designs[ url ][ _type ];
     }
     return _default;
   }
 
   /**
    * All of our thumbnails located here
    */
   private thumbnail_styles: any;
   public thumbnails( _type: string ): style_id{
     let thumbnail_style_id: style_id = { style_id: "", name: "default", type:"thumbnail" };
     if( this.thumbnail_styles && typeof this.thumbnail_styles[ _type ] != "undefined" ){
       thumbnail_style_id = <style_id>this.thumbnail_styles[ _type ];
     }
     return thumbnail_style_id;
   }
 
   /**
    * Logo Related Variables
    */
   public logo: logo;
   public logo_img: string = "";
   
 
   public filters: global_filter[];
 
   //We never access this variable directly, this is setted at Load, and then parsed into readable variable
   private loaded_setup: setup;
   public current_design: string = "global";
   public global_info: global_info;

   //Global Veriables Loaded from API
   public data_global: data_global[] = [];
   public data_setup: data_setup[] = [];
 
 
 
 
 }

 //20220123 :: SetupData loaded from api/global
 interface  data_global{
  variable_data_id: string;
  var_type: string;
  data: string;
 }
 interface data_setup{
  feature_id: string;
  name: string;
  value: string;
 }
 interface result_setup_data {
  data: {
    global: data_global[],
    setup: data_setup[]
  }
}

 //Setup Interface ( Which contains everything)
 interface setup{
   version: string;
   enabled: boolean;
   maintenanceMsg: update_msg;
   majorMsg: update_msg;
   minorMsg: update_msg;
   appstore: string;
   playstore: string;

   global: design;
   design: design[];
   logo: logo;
   thumbnail_style_id: style_id[];
   global_info: global_info;
 }
 interface update_msg{
  title?: string;
  msg?: string;
  btn?: string;
 }

interface global_info{

  footer_community_icon?: string,
  footer_community_label?: string,
  footer_chat_icon?: string,
  footer_chat_label?: string,
  footer_emergency_icon?: string,
  footer_emergency_label?: string,
  footer_notification_icon?: string,
  footer_notification_label?: string,
  footer_member_icon?: string,
  footer_member_label?: string,
  footer_shop_icon?: string,
  footer_shop_label?: string,

  home_hp_label?: string, home_hp_placeholder?: string, home_button_login?: string,

  otp_header?: string, otp_message?: string, otp_submit?: string, otp_cancel?: string, otp_placeholder?: string,

  member_title?: string, member_name_label?: string, member_name_placeholder?: string,
  member_button_edit_name?: string, member_button_edit_name_icon?: string,
  member_button_logout?: string, member_button_logout_icon?: string,
  member_subtitle_community?: string, member_subtitle_mobile_number?: string,
  member_subtitle_community_icon?: string, member_subtitle_mobile_number_icon?: string

  member_subtitle_calon?: string, member_subtitle_calon_icon?: string,
  member_subtitle_penghuni?: string, member_subtitle_penghuni_icon?: string,
  member_subtitle_pengurus?: string, member_subtitle_pengurus_icon?: string,
  member_subtitle_rejected?: string, member_subtitle_rejected_icon?: string,

  member_button_add_community?: string, member_button_add_community_icon?: string,
  member_button_add_mobile_number?: string, member_button_add_mobile_number_icon?: string,
  member_add_mobile_number_header?: string, member_add_mobile_number_message?: string,
  member_warning_title?: string, member_warning_icon?: string, member_warning_message?: string,
  
  choose_community_label?: string, 
  membership_detail_header?: string, membership_detail_message?: string,
  membership_detail_submit?: string, membership_detail_cancel?: string,
  membership_detail_info1_label?: string, membership_detail_info1_placeholder?: string,

  chat_input_icon?: string,
  chat_input_placeholder?: string,

  shop_title?: string,

  message_title?: string,


  notification_title?: string,


  error_name_title?: string, error_name_message?: string
}

 
 //Design Interface
 //Type GLOBAL design will be the default for everything, and every single other type can override everything!
 interface style_id{
   name: string;
   style_id: string;
   type: string;
   width?: number;
   height?: number;
 }
 interface logo{
   page_id: string;
   title?: string;
   subtitle?: string;
   title_font?: string;
   title_color?: string;
   subtitle_font?: string;
   subtitle_color?: string;
 
   //Loaded Dynamically
   url?: string[];
   url_original?: string[];
 }
 interface design{
   type: string;
   design_id: string;
   name: string;
   filters: global_filter[];
   theme?: theme;
   header_color?: string;
   footer_color?: string;
   menu_color?: string;
   popup_header_color?: string;
   price_color?: string;
   button1_color?: string;
   button2_color?: string;
   button3_color?: string;
   title1_color?: string;
   title2_color?: string;
   title3_color?: string;
 }
 
 interface global_filter{
   type: string;
   name: string;
   name_short: string;
   id: string;
   default: string;
   default_name: string;
 }
 
 //Theme Interfaces
 interface theme{
   name: string;
   colors: theme_color[];
   fonts: theme_font[];
   "background-color": string;
   "background-color-rgb": string;
   "text-color": string;
   "text-color-rgb": string;
   "border-color": string;
   "item-background": string;
   "toolbar-background": string;
   "toolbar-color": string;
   "tab-bar-background": string;
 }
 interface theme_color{
   name: string;
   base: string;
   rgb?: string;
   contrast?: string;
   "contrast-rgb"?: string;
   shade?: string;
   tint?: string;
 }
 interface theme_font extends general_style{
   "font-family": string;
   "font-size": string;
   "font-weight": string;
   "letter-spacing": string;
 }
 interface general_style{
   name: string;
   display: string;
   margin: string;
   padding: string;
 }