import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { InterceptorService } from './services/interceptor.service';
import { SetupService } from './services/setup.service';
import { IonicSelectableModule } from 'ionic-selectable';

import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
registerLocaleData( localeId );


export function initializeApp1(appInitService: SetupService) {
  return (): Promise<any> => { 
    return appInitService.Load();
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({ mode: 'md' }), 
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule, FormsModule, IonicSelectableModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'id'},
    { provide: APP_INITIALIZER,useFactory: initializeApp1, deps: [SetupService], multi: true}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}


// ================
// DATE EXTENSIONS
// ================

declare global {
  interface Date {
    getDateTimeLocal( _is_utc?: boolean, _has_second?: boolean ): string;
  }
}
Date.prototype.getDateTimeLocal = function( _is_utc, _has_second ){
  let returned_month = this.getMonth() + 1;
  let returned_date = this.getDate();
  let returned_hour = this.getHours();
  let returned_minute = this.getMinutes();
  let returned_second = this.getSeconds();
  let returned_string = this.getFullYear() + "-";
  if( _is_utc == true ){
    returned_month = this.getUTCMonth() + 1;
    returned_date = this.getUTCDate();
    returned_hour = this.getUTCHours();
    returned_minute = this.getUTCMinutes();
    returned_second = this.getUTCSeconds();
    returned_string = this.getUTCFullYear() + "-";
  }
  returned_string += ("0" + returned_month).slice(-2) + "-";
  returned_string += ("0" + returned_date).slice(-2) + "T";
  returned_string += ("0" + returned_hour).slice(-2) + ":";
  returned_string += ("0" + returned_minute).slice(-2)  ;
  if( _has_second ){
    returned_string += ":" + ("0" + returned_second).slice(-2) ;
  }
  
  return returned_string;
}