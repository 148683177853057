import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder/Inbox',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home', canLoad: [ AuthGuardService ],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'list/job', canLoad: [ AuthGuardService ],
    loadChildren: () => import('./list/master/master.module').then( m => m.MasterPageModule)
  },
  {
    path: 'list/transaction', canLoad: [ AuthGuardService ],
    loadChildren: () => import('./list/transaction/transaction.module').then( m => m.TransactionPageModule)
  },
  {
    path: 'list/member', canLoad: [ AuthGuardService ],
    loadChildren: () => import('./list/member/member.module').then( m => m.MemberPageModule)
  },
  {
    path: 'item-form', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./list/item-form/item-form.module').then( m => m.ItemFormPageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
