import { Injectable } from '@angular/core';
import { CanActivate, UrlSegment, Router, CanLoad } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Route } from '@angular/compiler/src/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad {

  constructor( 
    public authentication: AuthenticationService, private router: Router
  ) { }

  canActivate(): boolean{
    //Disable Login Requirement 
    //return true;
    return this.authentication.isAuthenticate();
  }
  canLoad(
    route: Route,
    seqments: UrlSegment[] ): Observable<boolean> | Promise<boolean> | boolean {
      if ( !this.authentication.isAuthenticate() ){
        this.router.navigateByUrl("/login");
      }
      return this.authentication.isAuthenticate();
  }
}
