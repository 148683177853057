import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { result_general, result_modified } from './interfaces.service';

/**
 * We will only have the variables global and result_global for on this service, because we will only used them here
 */
interface global {
  variable_data_id: string;
  var_type: string;
  data: string;
}
interface setup {
  feature_id: string;
  name: string;
  value: string;
}
interface invoice_form_setup{
  followup: follow_up_status[];
  status: follow_up_status[];

  message_all_oos?: string;
  message_some_oos?: string;
  message_no_oos?: string;
}
export interface follow_up_status{
  id: string;
  name: string;
  message: string;
  action: string;
  actions ?: follow_up_action[];
}
export interface follow_up_action{
  id: string;
  type ?: string;
  name: string;
  message: string;
  action: string;
}
interface template {
  type: string;
  name: string;
  action?: string;
  message?: string;
}
interface result_global extends result_general {
  data: {
    global: global[],
    setup: setup[]
  }
}


@Injectable({
  providedIn: 'root'
})
export class GlobalService {


  constructor( private http: HttpClient ) { }

  //Load all page with proper filter and cms_type
  public globals: global[]; 
  public setups: setup[];
  Load(): Observable<result_global>{
    let params = new HttpParams();
    return this.http.get<result_global>( environment.api_url + "/global", 
      { params: params } 
    ).pipe( tap( data => {
      console.log( data );
      this.globals = data.data.global;
      this.setups = data.data.setup;

      //Set all necessary Global Variables ( THE JSON )
      for( let global of this.globals ){
        if( global.var_type == "[JSON] Invoice Setup" ){
          try{
            this.invoice_setup = JSON.parse( global.data.replace(/\'/g, '"') );
            //Do this to check if everything exists
            if( this.invoice_setup.followup.length >= 0 || this.invoice_setup.status.length >= 0 ){
              
            }
          }catch( e ){
            //Allow default
          }
        }else if( global.var_type == "[JSON] Form Setup" ){
          try{
            this.form_setup = JSON.parse( global.data.replace(/\'/g, '"') );
            if( this.form_setup.followup.length >= 0 || this.form_setup.status.length >= 0 ){
              
            }
          }catch( e ){
            //Allow default
          }
        }
      }

      //Set all necessary Setup Variables
      for( let setup of this.setups ){
        if( setup.name == "Sold Out System" ){
          this.has_sold_out = true;
        }else if( setup.name == "Item Management System" ){
          this.has_item = true;
        }else if( setup.name == "Member Management System" ){
          this.has_member = true;
        }else if( setup.name == "PO System" ){
          this.has_po = true;
        }
      }
    }));
  }

  FormatAction( _data: follow_up_status[] ){

    for( let single_data of _data ){
      if( single_data.actions === undefined ){
        let action_arr: string[] = single_data.action.split(",");
        let actions: follow_up_action[] = []; 
      }
    }
  }

  ClearCache(): Observable<result_modified>{
    let params = new HttpParams();
    console.log( environment.api_url + "/global/clear" );
    return this.http.get<result_modified>( environment.api_url + "/global/clear", 
      { params: params } 
    ).pipe( tap( data => {

    }));
  }

  /**
   * Global JSON
   */
  public form_setup: invoice_form_setup = {
    'followup': [
        { 
            'id':'f1',
            'name':'Pertama',
            'message':'Template Follow Up 1',
            'action':'email,wa'
        },
        { 
            'id':'f2',
            'name':'Kedua',
            'message':'Template Follow Up 2',
            'action':'email,wa'
        },
        { 
            'id':'f3',
            'name':'Ketiga',
            'message':'Template Follow Up 3',
            'action':'email,wa'
        },
        { 
            'id':'f4',
            'name':'Keempat',
            'message':'Template Follow Up 4',
            'action':'email,wa'
        }
    ],
    'status': [
        {
            'id':'new',
            'name':'Form Baru',
            'message':'',
            'action':'step1,completed,cancelled'
        },
        {
            'id':'step1',
            'name':'Sudah Dicek',
            'message':'',
            'action':'step2,completed,cancelled'
        },
        {
            'id':'step2',
            'name':'Sudah Dicek Dua Kali',
            'message':'',
            'action':'step3,completed,cancelled'
        },
        {
            'id':'step3',
            'name':'Siap Dikirim',
            'message':'',
            'action':'completed,cancelled'
        },
        {
            'id':'completed',
            'name':'Form Selesai',
            'message':'',
            'action':''
        },
        {
            'id':'cancelled',
            'name':'Form Batal',
            'message':'',
            'action':''
        }
    ]
  };
  public invoice_setup: invoice_form_setup = {
    'followup': [
        { 
            'id':'f1',
            'name':'Pertama',
            'message':'Template Follow Up 1',
            'action':'email,wa'
        },
        { 
            'id':'f2',
            'name':'Kedua',
            'message':'Template Follow Up 2',
            'action':'email,wa'
        },
        { 
            'id':'f3',
            'name':'Ketiga',
            'message':'Template Follow Up 3',
            'action':'email,wa'
        },
        { 
            'id':'f4',
            'name':'Keempat',
            'message':'Template Follow Up 4',
            'action':'email,wa'
        }
    ],
    'status': [
        {
            'id':'new',
            'name':'Transaksi Baru',
            'message':'',
            'action':'paid,completed,cancelled'
        },
        {
            'id':'confirmation',
            'name':'Konfirmasi Pembayaran',
            'message':'',
            'action':'paid,completed,cancelled'
        },
        {
            'id':'paid',
            'name':'Pembayaran Diterima',
            'message':'',
            'action':'completed,cancelled'
        },
        {
            'id':'completed',
            'name':'Transaksi Selesai',
            'message':'',
            'action':''
        },
        {
            'id':'cancelled',
            'name':'Transaksi Dibatalkan',
            'message':'',
            'action':''
        }
    ],
    'message_all_oos': 'Dari keseluruhan order anda yang sudah kami proses, semua produk Out of Stock! Invoice ini akan di cancel!',
    'message_no_oos': 'Dari keseluruhan order anda yang sudah kami proses, tidak ada produk yang Out of Stock (OOS)',
    'message_some_oos': 'Dari keseluruhan order anda yang sudah kami proses, dengan detail produk yang Out of Stock (OOS) sbb : ~oos_message~ . Total Refund adalah Rp ~refund~ , Balance Rp ~wallet_balance~Informasi lengkap mengenai wallet anda dapat di cek dengan login kembali di website kami. Nilai di wallet akan otomatis menjadi nilai pengurang untuk orderan anda selanjutnya atau bisa click tombol Refund ke Rekening di wallet tersebut.'
  };

  /**
   * All of the accessible Global Variables ( once used, we will add it here )
   */
  public has_sold_out: boolean = false;
  public has_item: boolean = false;
  public has_member: boolean = false;
  public has_po: boolean = false;
  

}
