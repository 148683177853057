import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EditPasswordComponent } from './login/edit-password/edit-password.component';
import { AuthenticationService } from './services/authentication.service';
import { FileService, file_parent } from './services/file.service';
import { SetupService } from './services/setup.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(
    public setup_service: SetupService, public auth_service: AuthenticationService, private file_service: FileService,
    private modal_controller: ModalController
  ) {

  }

  on_edit_password(){
    this.modal_controller.create({
      component: EditPasswordComponent, id: "EditPassword"
    }).then( modalEl => {
      modalEl.present();
    })
  }

  on_edit_pp(){
    
    let target: file_parent = {
      primary_id: this.auth_service.staff_id,
      cms_type: "staff",
      edit_type: "page",
      upload_type: "pp"
    };
    if( this.auth_service.type == "member" ){
      target = {
        primary_id: this.auth_service.member_id,
        cms_type: "member",
        edit_type: "page",
        upload_type: "pp"
      };
    }
    this.file_service.on_file_select( target, null, _ => { this.on_refresh(); } );
  }

  on_refresh(){
    console.log( "Try To Refresh The Staff Details" );
    this.auth_service.on_refresh().subscribe( _data => {
      console.log( _data );
    });
  }

}
