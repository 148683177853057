// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_client_id: 3701,
  
  
  base_url: "https://api-dev3.ikt.co.id/",
  api_url: "https://api-dev3.ikt.co.id/3701",
  external_url: "https://api-dev3.ikt.co.id/3701/external",
  /* */
  
  /*
  base_url: "http://localhost/",
  api_url: "http://localhost/3300",
  /* */

  logo: "/assets/design/LOGO utama.svg",
  whatsapp: "whatsapp://send?phone=628172458458",
  token_key: "5aa33dad523d9692425883bb2f16bf44c7f04700",
  domain: "siplah.ikt.co.id"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
