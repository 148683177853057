import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {

  @Input() mobile_number: string = "";
  @Input() salt: string = "";
  @Input() type: string = "";
  otp: number = null;
  second: number = 30;
  
  get timer_second():string{
    return ("0" + this.second).slice(-2);
  }
  otp_timer = null;

  @ViewChild("digit0") digit0: ElementRef;
  @ViewChild("digit1") digit1: ElementRef;
  @ViewChild("digit2") digit2: ElementRef;
  @ViewChild("digit3") digit3: ElementRef;
  @ViewChild("digit4") digit4: ElementRef;
  @ViewChild("digit5") digit5: ElementRef;
  @ViewChild("digit6") digit6: ElementRef;
  digits: any[] = ["","","","","",""];
  on_input_click( _event ){
    let this_digit = parseInt( _event.target.name.replace("digit", "") );
    this["digit" + this_digit].nativeElement.value = '';
  }
  on_input_keypress( _event ){
    console.log( _event );
    return false;
    if (_event.charCode != 0) {
      var regex = new RegExp("^[0-9]+$");
      var key = String.fromCharCode(!_event.charCode ? _event.which : _event.charCode);
      if (!regex.test(key)) {
          _event.preventDefault();
          return false;
      }
      let this_digit = parseInt( _event.target.name.replace("digit", "") );
      let next_digit = this_digit + 1;
      if( next_digit <= 6 ){
        setTimeout(()=>{
          this["digit" + next_digit].nativeElement.focus();
        },20);
      }
    }
  }
    
  on_input_keydown( _event ){
    //console.log( "Input Keydown", _event );
    let this_digit = parseInt( _event.target.name.replace("digit", "") );
    let prev_digit = this_digit - 1;
    if ((_event.which == 8 || _event.which == 46) && this["digit" + this_digit].nativeElement.value == '') {
      
      if( prev_digit > 0 ){
        setTimeout(()=>{
          this["digit" + prev_digit].nativeElement.focus();
        },20);
      }
    } else {
      this["digit" + this_digit].nativeElement.value = "";
    }
    
  }

  constructor(
    private loading_controller: LoadingController,
    private modal_controller: ModalController,
    public setup_service: SetupService,
    private auth_service: AuthenticationService,
    private alert_controller: AlertController
  ) {

  }


  ngOnInit() {
    this.on_retry();

  }

  on_submit(){
    /*
    let otp: string = "";
    otp += this.digit1.nativeElement.value;
    otp += this.digit2.nativeElement.value;
    otp += this.digit3.nativeElement.value;
    otp += this.digit4.nativeElement.value;
    otp += this.digit5.nativeElement.value;
    otp += this.digit6.nativeElement.value;
    */
   console.log( this.otp );
    if( this.otp  >= 100000 ){
      //Perform Login
      this.loading_controller.create({
        message: "Login....."
      }).then( loadingEl => {
        loadingEl.present();
        let otp_data = {
          mobile_number: this.mobile_number,
          otp: this.otp,
          salt: this.salt,
          type: this.type
        };
        this.auth_service.login_otp( otp_data ).subscribe( _data => {
          console.log( _data );
          if( _data.status == "ok" ){
            this.modal_controller.dismiss( _data, "cancel", "otp" );
          }else{
            /*
            //Unecessary
            this.alert_controller.create({
              header: "Error",
              message: _data.status_message,
              buttons: ["OK"]
            }).then( alertEl => {
              alertEl.present();
            });
            */
          }
        }).add( _ => {
          loadingEl.dismiss();
        });
      });
    }else{
      //Error Alert
      this.alert_controller.create({
        message: "Masukan 6 angka kode OTP",
        buttons: ["OK"]
      }).then( alertEl => {
        alertEl.present();
      });
    }
    console.log( this.digits, this.otp );
    //this.modal_controller.dismiss( this.get_data("submit"), "cancel", "otp" );
  }

  //This is called first time on constructor
  on_retry(){
    this.loading_controller.create({
      message: "Mengirim Kode OTP"
    }).then( loadingEl => {
      loadingEl.present();
      this.second = 30;
      if( this.otp_timer != null ){
        clearInterval( this.otp_timer );
      }
      this.otp_timer = setInterval( _ => {
        if( this.second > 0){
          this.second--;
          //clearInterval(downloadTimer);
        }else{
          clearInterval( this.otp_timer );
          this.otp_timer = null;
        }
      }, 1000);
      this.auth_service.login_otp({ 
        mobile_number: this.mobile_number,
        type: this.type
      }).subscribe( _data => {
        console.log( _data );
        if( _data.status == "warning" ){
          this.salt = _data.data.salt;
          //this.otp = parseInt(_data.data.otp);
        }
      }).add( _ => {
        loadingEl.dismiss();
      });
    });
  }

  on_dismiss(){
    this.modal_controller.dismiss( this.get_data("cancel"), "cancel", "otp" );
  }

  get_data( _action ){
    return {
      mobile_number: this.mobile_number,
      salt: this.salt,
      type: this.type,
      otp: this.otp,
      action: _action
    };
  }

}
