import { Injectable, ɵɵsetComponentScope } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';

import { Platform, ToastController, AlertController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { environment } from 'src/environments/environment';
import { member, result_login, result_modified } from './interfaces.service';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { SetupService } from './setup.service';
import { CommunityService } from './community.service';
import { OtpComponent } from '../components/otp/otp.component';






//20211219 :: Import for notification
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

//20211219 :: We must also retrieve the App & Device Information
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  
  authState = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private storage: Storage,
    private platform: Platform,
    public toastCtrl: ToastController,
    public http: HttpClient,
    private alertCtrl:AlertController,
    private modalCtrl: ModalController,
    private global_service: GlobalService,
    private setup_service: SetupService,
    private community_service: CommunityService
  ) { 
    this.platform.ready().then(() => {
      this.init_storage();
    })
  }

  async init_storage(){
    await this.storage.create();
    this.ifLoggedIn();
  }

  //Member Load Account Page
  member_load() {
    let url = environment.api_url;
    if( this.type == "staff" ){
      url += "/staff";
    }else{
      url += "/member";
    }
    return this.http.get<any>( url ).pipe(
      tap( resData => {
        if( resData && resData.status && resData.status == "ok" ){
          
        }else{
          //Show a popup and then remove
        }
      }),
      catchError(this.handleError('MEMBER EDIT PIPE ERROR', []))
    );
  }


  //Use this to retrieve all of the necessary member_information stored on our system
  //This is called on our Constructor and also on our sign_in method
  //If initial_loaded == false, then our CanActivate must always get_member_storage and wait for it to finish!
  public initial_loaded: boolean = false;
  async get_member_storage( _refresh: boolean = true ): Promise<void>{
    await this.storage.get( "account_token" ).then((val) => {
      if( val != null ){
        this._account_token = val;
      }
      
    });
    await this.storage.get( "member_id" ).then((val) => {
      if( val != null ){
        this._member_id = val;
      }
      
    });
    await this.storage.get( "staff_id" ).then((val) => {
      if( val != null ){
        this._staff_id = val;
      }
    });
    await this.storage.get( "privileges" ).then((val) => {
      if( val != null ){
        this._privileges = val;
      }
    });

    await this.storage.get( "department" ).then((val) => {
      if( val != null ){
        this._department = val;
      }
    });
    await this.storage.get( "profile_picture" ).then((val) => {
      if( val != null ){
        this._profile_picture = val;
      }
    });

    await this.storage.get( "type" ).then((val) => {
      if( val != null ){
        this._type = val;
      }
    });
    await this.storage.get( "email" ).then((val) => {
      if( val != null ){
        this.email = val;
      }
    });
    await this.storage.get( "zone" ).then((val) => {
      if( val != null ){
        this.zone = val;
      }
    });

    await this.storage.get( "member_name" ).then((val) => {
      if( val != null ){
        this._member_name = val;
      }
    });
    await this.storage.get( "mobile_number" ).then((val) => {
      if( val != null ){
        this.mobile_number = val;
      }
    });
    await this.storage.get( "gender" ).then((val) => {
      if( val != null ){
        this.gender = val;
      }
    });
    await this.storage.get( "date_of_birth" ).then((val) => {
      if( val != null ){
        this.date_of_birth = val;
      }
    });
    await this.storage.get( "info_1" ).then((val) => {
      if( val != null ){
        this.info_1 = val;
      }
    });
    await this.storage.get( "page_category_id" ).then((val) => {
      if( val != null ){
        this._page_category_id = val;
      }
    });
    await this.get_community_id();
    this.initial_loaded = true;
    if( _refresh ){
      this.logged_in();
    }
  }
  //This is the SET Counterparts
  async set_member_storage( resData: result_login, _refresh: boolean = true ): Promise<void>{
    await this.storage.set( "account_token", resData.data.account_token );
    await this.storage.set( "email", resData.data.email );
    
    await this.storage.set( "staff_id", resData.data.staff_id );
    await this.storage.set( "member_id", resData.data.member_id );
    if( typeof resData.data.staff_id != "undefined" && resData.data.staff_id.length > 0 ){
      await this.storage.set( "type", "staff" );
    }else{
      await this.storage.set( "type", "member" );
    }
    await this.storage.set( "privileges", resData.data.privileges );
    await this.storage.set( "department", resData.data.department );
    await this.storage.set( "profile_picture", resData.data.profile_picture );

    await this.storage.set( "member_name", resData.data.name );
    await this.storage.set( "mobile_number", resData.data.mobile_number );
    await this.storage.set( "gender", resData.data.gender );
    await this.storage.set( "date_of_birth", resData.data.date_of_birth );
    await this.storage.set( "info_1", resData.data.info_1 );
    await this.storage.set( "zone", resData.data.zone );
    await this.storage.set( "page_category_id", resData.data.page_category_id );

    console.log( "Set Member Storage", resData );

    /*
    //Why is this necessary?
    await this.storage.set( "community_id", 0 );
    await this.storage.set( "community_name", "" );
    await this.storage.set( "community_location", "" );
    await this.storage.set( "membership_type", "" );
    await this.storage.set( "membership_privileges", "" );
    */

    //console.log( "Set Member Storage", _refresh );
    //20211219 :: This is not necessary I think O.O;
    //this.get_member_storage( _refresh );
  }

  async set_community_id( _community_id: any, _community_name: string, _community_location: string, _membership_type: string, _membership_privileges: string ): Promise<void>{
    console.log( "Set Community ID", _community_id );
    await this.storage.set( "community_id", _community_id );
    await this.storage.set( "community_name", _community_name );
    await this.storage.set( "community_location", _community_location );
    await this.storage.set( "membership_type", _membership_type );
    await this.storage.set( "membership_privileges", _membership_privileges );
    this.get_community_id();
  }
  async get_community_id():Promise<void>{
    await this.storage.get( "community_id" ).then((val) =>{
      if( val != null ){
        if( this.community_service.selected_community_id > 0 && val == 0 ){
          //20211218 :: Do Nothing ( Request by Client )
        }else{
          this.community_service.selected_community_id = val;
          console.log( this.community_service.selected_community_id );
        }
        
      }
    });
    await this.storage.get( "community_name" ).then((val) =>{
      if( val != null ){
        this.community_service.selected_community_name = val;
        console.log( this.community_service.selected_community_name );
      }
    });
    await this.storage.get( "community_location" ).then((val) =>{
      if( val != null ){
        this.community_service.selected_community_location = val;
        console.log( this.community_service.selected_community_location );
      }
    });
    await this.storage.get( "membership_type" ).then((val) =>{
      if( val != null ){
        this.community_service.selected_membership_type = val;
      }
    });
    await this.storage.get( "membership_privileges" ).then((val) =>{
      if( val != null ){
        this.community_service.selected_membership_privileges = val;
      }
    });
  }

  ifLoggedIn(){
    this.get_member_storage( );
  }

  login( data ): Observable<any> {
    this._forced_logout_happened = false;
    let api_url: string = environment.api_url;
    if( data.type == "staff" ){
      api_url += "/staff/signin";
    }else{
      api_url += "/member/signin";
    }
    return this.http.post<result_login>( api_url, data ).pipe(
      tap( async resData => {
        if( resData && resData.status ){
          console.log( resData );
          if( resData.status == "warning" ){
            const modal = await this.modalCtrl.create({
              id: "otp",
              component: OtpComponent,
              componentProps: { 
                mobile_number: data.mobile_number,
                salt: resData.data.salt,
                type: data.type
              }
            });
            
            modal.onDidDismiss().then( (otp_data) => {
              console.log( otp_data );
              this.login( otp_data ).subscribe( _data => {
                console.log( _data );
              });
            });

            await modal.present();

            /*
            this.alertCtrl.create({
              header: this.setup_service.global_info.otp_header,
              message: this.setup_service.global_info.otp_message,
              inputs: [
                {
                  name: "otp",
                  placeholder: this.setup_service.global_info.otp_placeholder,
                  type: "number"
                }
              ],
              buttons:[
                {
                  text: this.setup_service.global_info.otp_submit,
                  handler: alert_data => {
                    let otp_data: any = {
                      mobile_number: data.mobile_number,
                      salt: resData.data.salt,
                      otp: alert_data.otp,
                      type: data.type
                    };
                    //This is already too long, so we can continue on separate method
                    this.login( otp_data ).subscribe( _data => {
                      console.log( _data );
                    });
                  }
                },
                {
                  text: this.setup_service.global_info.otp_cancel,
                  role: "cancel"
                }
              ]
            }).then( alertEl => {
              alertEl.present();
            });
            */
          }else if( resData.status == "ok"  ){
            if( resData.data.verified == 0 ){
              //Display Verification Window
              this.alertCtrl.create({
                header: "Login Error",
                message: "Akun Sprinter belum diverifikasi",
                buttons: ['Dismiss']
              }).then( alertEl => {
                alertEl.present();
              }); 
            }else if( resData.data.page_category_id == "3347_39" ){
              //Display Verification Window
              this.alertCtrl.create({
                header: "Login Error",
                message: "Akun Sprinter belum diaktifasi",
                buttons: ['Dismiss']
              }).then( alertEl => {
                alertEl.present();
              }); 
            }else if( resData.data.account_token.length > 0 ){
              this.set_member_storage( resData ).then( _ => this.get_member_storage( true ) );
            }
          }else{
            this.logged_out();  
          }
        }else{
          this.logged_out();
        }
      }), 
      catchError(this.handleError('LOGIN PIPE ERROR', []))
    );
  }

  login_otp( data ): Observable<any>{
    this._forced_logout_happened = false;
    let api_url: string = environment.api_url;
    if( data.type == "staff" ){
      api_url += "/staff/signin";
    }else{
      api_url += "/member/signin";
    }
    return this.http.post<result_login>( api_url, data ).pipe(
      tap( async resData => {
        if( resData && resData.status ){
          if( resData.status == "warning" ){
            //Do nothing ( but we can retrieve the salt here )

            /*
            this.alertCtrl.create({
              header: this.setup_service.global_info.otp_header,
              message: this.setup_service.global_info.otp_message,
              inputs: [
                {
                  name: "otp",
                  placeholder: this.setup_service.global_info.otp_placeholder,
                  type: "number"
                }
              ],
              buttons:[
                {
                  text: this.setup_service.global_info.otp_submit,
                  handler: alert_data => {
                    let otp_data: any = {
                      mobile_number: data.mobile_number,
                      salt: resData.data.salt,
                      otp: alert_data.otp,
                      type: data.type
                    };
                    //This is already too long, so we can continue on separate method
                    this.login( otp_data ).subscribe( _data => {
                      console.log( _data );
                    });
                  }
                },
                {
                  text: this.setup_service.global_info.otp_cancel,
                  role: "cancel"
                }
              ]
            }).then( alertEl => {
              alertEl.present();
            });
            */
          }else if( resData.status == "ok"  ){
            if( resData.data.verified == 0 ){
              //Display Verification Window
              this.alertCtrl.create({
                header: "Login Error",
                message: "Member belum diverifikasi",
                buttons: ['Dismiss']
              }).then( alertEl => {
                alertEl.present();
              }); 
            }else if( resData.data.page_category_id == "3347_39" ){
              //Display Verification Window
              this.alertCtrl.create({
                header: "Login Error",
                message: "Akun Vendor belum diaktifasi",
                buttons: ['Dismiss']
              }).then( alertEl => {
                alertEl.present();
              }); 
            }else if( resData.data.account_token.length > 0 && resData.data.verified == 1 ){
              this.set_member_storage( resData ).then( _ => this.get_member_storage( true ) );
            }
          }else{
            this.logged_out();  
          }
        }else{
          this.logged_out();
        }
      }), 
      catchError(this.handleError('LOGIN PIPE ERROR', []))
    );
  }

  
  async logged_in(){
    console.log( "Logged In", this.account_token );
    if( this.account_token != "" && (this.member_id != "" || this.staff_id != "") && this.type != "" ){
      console.log( "Check Logged In", this.account_token, this.community_service.selected_community_id );

      this._user_signed_in = true;
      this.authState.next(true);
      this.router.navigateByUrl("/list/member");

      //20211219 :: Execute Final Notification
      this.init_notification();
    }else{
      this.router.navigateByUrl("/login");
    }
  }

  on_refresh(): Observable<any> {
    let api_url: string = environment.api_url;
    if( this.type == "staff" ){
      api_url += "/staff/refresh";
    }else{
      api_url += "/member/refresh";
    }
    console.log( "On Refresh", api_url );
    return this.http.get<result_login>( api_url, { params: null } ).pipe(
      tap( async resData => {
        if( resData && resData.status ){

          console.log( resData );
          if( resData.status == "ok"  ){
            if( resData.data.account_token.length > 0 ){
              this.set_member_storage( resData ).then( _ => this.get_member_storage( true ) );
            }
          }else{
            this.logged_out();  
          }
        }else{
          this.logged_out();
        }
      }), 
      catchError(this.handleError('LOGIN REFRESH PIPE ERROR', []))
    );
  }

  //20211219 :: Notification for our Android / iOS for now
  async init_notification(){
    let platform: string = Capacitor.getPlatform();
    //20211219 :: Also Retrieve Device Information and App Information
    const device_info = await Device.getInfo();
    const device_id = await Device.getId();

    let device_data: device_data = {
      device_id: device_id.uuid,
      device_manufacturer: device_info.manufacturer,
      device_model: device_info.model,
      device_os: device_info.operatingSystem,
      device_os_version: device_info.osVersion,
      device_platform: device_info.platform,
      app_version: "",
      device_token: ""
    };
      
      if( platform == "web" ){
        //We do not have PushNotification for web
        console.log( "Ignore Notification for platform Web" );
        this.register( device_data ).subscribe( _data => {
          console.log( "Device Registration", device_data, _data );
        });
      }else{
        console.log('Initializing Notification', this.platform.platforms() );
        const app_info = await App.getInfo();
        device_data.app_version = app_info.version;
        console.log( app_info );

        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        let token_registered: boolean = false;
        PushNotifications.requestPermissions().then(result => {
          console.log( "Notification Request Permission", result );
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
            if( token_registered == false ){
              token_registered = true;
              this.register( device_data ).subscribe( _data => {
                console.log( "Device Registration Not Granted", device_data, _data );
              });
            }
          }
        });
    
        // On success, we should be able to receive notifications
        
        PushNotifications.addListener('registration',
          (token: Token) => {
            console.log( "Notification Registration", token );
            if( token_registered == false ){
              token_registered = true;
              device_data.device_token = token.value;
              this.register( device_data ).subscribe( _data => {
                console.log( "Device Registration", device_data, _data );
              });
            }
            
            //alert('Push registration success, token: ' + token.value);
          }
        );
    
        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
          (error: any) => {
            console.log( "Notification RegistrationError", error );
            if( token_registered == false ){
              token_registered = true;
              this.register( device_data ).subscribe( _data => {
                console.log( "Device Registration Error", device_data, _data );
              });
            }
            //alert('Error on registration: ' + JSON.stringify(error));
          }
        );
    
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            console.log( "Notification Received", notification );
            //alert('Push received: ' + JSON.stringify(notification));
          }
        );
    
        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
          (notification: ActionPerformed) => {
            console.log( "Notification ActionPerformed", notification );
            this.notification_clicked( notification.notification.data );
            //alert('Push action performed: ' + JSON.stringify(notification));
          }
        );
      }

      //this.setup_service.Load().then( _result => {
      //  console.log( "Initialize App", _result );
      //});
  }

  notification_clicked( _data ){
    console.log( _data );
    if( typeof _data["community_qr_id"] != "undefined" && _data["community_qr_id"] > 0 ){
      this.router.navigateByUrl("/notification/qr/" + _data["community_qr_id"] );
    }else if( typeof _data["chat_id"] != "undefined" && _data["chat_id"] > 0 ){
      //Go to Chat opening the chat room of chat_id!
      this.router.navigateByUrl("/message/message-history/" + _data["chat_id"] + "/" + _data["chat_history_id"] );
    }else if( typeof _data["page_id"] != "undefined" ){
      //Go to Detail Page
      this.community_service.selected_community_id = _data["community_id"];
      this.router.navigateByUrl("/community/community-post-detail/" + _data["page_id"] );
    }
  }

  register( _data: device_data ):Observable<result_modified>{
    let api_url: string = environment.api_url + "/member/device";
    return this.http.post<result_modified>( api_url, _data );
  }

  //This flag is resetted at login attempt
  private _forced_logout_happened: boolean = false;
  public get forced_logout_happened():boolean{
    return this._forced_logout_happened;
  }
  logout( _forced: boolean = false ){
    //20210617 :: Forced Logout can only ever happened once
    if( _forced && this.forced_logout_happened ){
      console.log( "STOP FORCED LOGOUT TERJADI, krn sudah pernah terjadi sekali!" );
      return false;
    }else if( _forced ){
      console.log( "FORCED LOGOUT TERJADI, catat tapi next time udah kaga boleh!" );
      //return false;
    }
    console.log( "Logout" );
    this._forced_logout_happened = _forced ;
    //Execute SIGN OUT
    if( this.account_token != "" && this.account_token.length > 0 ){
      let api_url: string = environment.api_url;
      if( false ){
        api_url += "/staff/signout";
      }else{
        api_url += "/member/signout";
      }
      let data = [];
      this.http.post<result_login>( api_url, data ).subscribe( _data => {
        console.log("Logout Successful", _data );
      });
    }
    
    this.logged_out();
  }

  public logged_out(){
    this.storage.clear().then( _ => {
      this._user_signed_in = false;
      
      this._account_token = "";


      this._member_id = "";


      this._staff_id = "";

      this._privileges = "";

      this._department = "";
      this._profile_picture = "";

      this._type = "";

      this.email = "";

      this.zone = 0;

      this._member_name = "";

      this.mobile_number = "";

      this.gender = "";

      this.date_of_birth = "";

      this.info_1 = "";

      this._page_category_id = "";

          
      this.community_service.selected_community_id = 0;

      this.community_service.selected_community_name = "";

      this.community_service.selected_community_location = "";

      this.community_service.selected_membership_type = "";

      this.community_service.selected_membership_privileges = "";
           
       
      this.router.navigateByUrl( "/home" );
      this.authState.next(false);
    });
  }

  on_edit_password( _data ):Observable<result_modified>{
    return this.http.put<result_modified>( environment.api_url + "/" + this.type + "/password", _data );
  }

  isAuthenticate(){
    return this.authState.value;
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }


  //Network Authentication Variables
  private _user_signed_in = false;
  get user_signed_in(){
    return this._user_signed_in;
  }
  private _account_token = "";
  private _member_id = "";
  private _page_category_id = "";
  private _member_name = "";
  
  public info_1 = "";
  public email = "";
  public gender = "";
  public mobile_number = "";
  public date_of_birth = "";
  public zone = 0;
  get account_token(){
    return this._account_token;
  }
  get member_id(){
    return this._member_id;
  }
  get page_category_id(){
    return this._page_category_id;
  }
  get member_name(){
    return this._member_name;
  }
  get member_pembeli(){
    if( this.user_signed_in == false || ( this.page_category_id != null && ( this.page_category_id == "3347_39" || this.page_category_id == "3347_40" ) ) ){
      return false;
    }
    return true;
  }
  get member_pengurus(){
    if( this.user_signed_in == true && ( this.community_service.selected_membership_type == "pengurus" || this.community_service.selected_membership_type == "admin" || this.staff_id != "" ) ){
      return true;
    }
    return false;
  }
  

  //20210516 :: New Pengguna ID and TOKEN
  private _type = "";
  private _staff_id = "";
  get staff_id(){
    return this._staff_id;
  }
  get type(){
    return this._type;
  }

  //20211127 :: For now privileges are not used, because this privileges is stored at LOGIN LEVEL
  private _privileges = "";
  get privileges(){
    return this._privileges;
  }

  //20220228 :: Samewani CMS
  private _department = "";
  public get department(){
    return this._department;
  }
  public get can_add_member(): boolean{
    return this._department == "MARKETING" || this._department == "ADMINISTRATOR";
  }
  private _profile_picture = "";
  public get profile_picture(){
    return this._profile_picture;
  }

  
  public selected_member_data: member = null;
  public get selected_member_id(): string{
    if( this.selected_member_data == null ){
      return "";
    }
    return this.selected_member_data.member_id;
  }
  public get selected_member_name(): string{
    if( this.selected_member_data == null ){
      return "";
    }
    return this.selected_member_data.name;
  }
  public get selected_member_email(): string{
    if( this.selected_member_data == null ){
      return "";
    }
    return this.selected_member_data.email;
  }
  public get selected_member_profile_picture(): string {
    if( this.selected_member_data == null ){
      return "";
    }
    return this.selected_member_data.image_url_pp[0];
  }


}


interface device_data{
  device_id?: string;
  device_token?: string;
  device_manufacturer?: string;
  device_model?: string;
  device_os?: string;
  device_os_version?: string;
  device_platform?: string;
  app_version?: string;
}